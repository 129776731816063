<template>
	<div class="w-full">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white"
		>
			<span class="ml-2 font-bold">Agregando proveedor</span>
			<div class="flex items-center justify-center">
				<router-link to="/Proveedores"
					><button
						@click="$emit('click')"
						class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
					>
						<svg
							version="1.1"
							id="Capa_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							viewBox="0 0 368.022 368.022"
							style="enable-background:new 0 0 368.022 368.022;"
							class="h-6 w-6"
							xml:space="preserve"
						>
							<path
								style="fill:#D7443E;"
								d="M314.101,54.133c71.79,71.847,71.744,188.287-0.103,260.077s-188.287,71.744-260.077-0.103 c-71.75-71.806-71.75-188.167,0-259.974c71.381-71.847,187.49-72.224,259.337-0.843C313.54,53.57,313.821,53.851,314.101,54.133z"
							/>
							<polygon
								style="fill:#ffffff;"
								points="275.439,124.663 215.88,184.223 275.439,243.782 243.57,275.651 184.011,216.092 124.452,275.651 92.582,243.782 152.142,184.223 92.582,124.663 124.452,92.794 184.011,152.353 243.57,92.794"
							/>
						</svg>
						<span class="underline ml-2">Cancelar</span>
					</button></router-link
				>
			</div>
		</div>
		<div class="flex w-full mt-4 justify-center" v-on="datosGenerales()">
			<table class="table-fixed">
				<tr>
					<th colspan="2" class="border-2 px-4 py-2 bg-gray-200">
						Datos personales
					</th>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Razon Social</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="localId[0].user.name"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">C.I. - RIF</th>
					<td class="border-2 px-4 py-2 w-64">
						<div class="flex content-center">
							<select
								:id="select"
								@change="cedulaPrefijoDatosPersonales($event)"
								class="ml-1 bg-white"
							>
								<option disabled selected value="">{{
									prefijoCedula(localId[0].user.cedula_identidad)
								}}</option>
								<option value="V">V</option>
								<option value="J">J</option>
								<option value="G">G</option>
								<option value="E">E</option>
							</select>
							<input
								class="ml-1 text-center"
								type="text"
								v-model="localId[0].user.cedula_identidad"
							/>
						</div>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Telefono movil</th>
					<td class="border-2 px-4 py-2 w-64">
						<div class="flex content-center">
							<select
								id="select"
								@change="telefonoMovilPrefijo($event)"
								class="ml-1 bg-white"
							>
								<option disabled selected value="">{{
									prefijoTelefono(localId[0].user.numero_telefono_movil)
								}}</option>
								<option>0412</option>
								<option>0414</option>
								<option>0424</option>
								<option>0416</option>
								<option>0426</option>
							</select>
							<input
								class="ml-1 text-center"
								type="text"
								v-model="localId[0].user.numero_telefono_movil"
							/>
						</div>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Telefono local</th>
					<td class="border-2 px-4 py-2 w-64 content-center">
						<div class="flex content-center">
							<span>0212- </span
							><input
								class="ml-1"
								type="text"
								v-model="localId[0].user.numero_telefono_local"
							/>
						</div>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Email</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="email"
							v-model="localId[0].user.email"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Disponibilidad</th>
					<td class="border-2 px-4 py-2 w-64">
						<select
							name="estado"
							v-model="localId[0].show"
							class="px-1 ml-2 focus:outline-none bg-white"
							:class="
								localId[0].show === '1' ? 'text-green-500' : 'text-red-500'
							"
						>
							<option value="1">Disponible</option>
							<option value="0">No disponible</option>
						</select>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Rubro</th>
					<td class="border-2 px-4 py-2 w-64">
						<v-select multiple v-model="selectRubro" :options="opciones" />
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Alias local</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="localId[0].nombre"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Ubicación</th>
					<td class="border-2 px-4 py-2 w-64">
						<div class="flex content-center">
							<select
								id="select"
								class="ml-1 bg-white"
								v-model="localId[0].ubicacion"
							>
								<option disabled selected value="">{{
									localId[0].ubicacion
								}}</option>
								<option>Planta Baja</option>
								<option>Mezzanina</option>
								<option>Comercial</option>
							</select>
						</div>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">% Descuento</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="localId[0].descuento_general"
						/>
					</td>
				</tr>
			</table>

			<table class="table-fixed mx-10">
				<tr>
					<th colspan="2" class="border-2 px-4 py-2 bg-gray-200">
						Datos Bancarios 1
					</th>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Banco</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].banco_1"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Nombre</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].nombre_1"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">C.I. - RIF</th>
					<td class="border-2 px-4 py-2 w-64 flex">
						<select
							:id="select"
							@change="cedulaPrefijoBanco1($event)"
							class="ml-1 bg-white"
						>
							<option disabled selected value="">{{
								prefijoCedula(datosIdBancarios[0].cedula_1)
							}}</option>
							<option value="V">V</option>
							<option value="J">J</option>
							<option value="G">G</option>
							<option value="E">E</option>
						</select>
						<input
							class="w-full ml-4 focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].cedula_1"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Tipo de cuenta</th>
					<td class="border-2 px-4 py-2 w-64">
						<select
							name="estado"
							v-model="datosIdBancarios[0].tipo_cuenta_1"
							class="px-1 ml-2 focus:outline-none bg-white"
						>
							<option value="0">Ahorro</option>
							<option value="1">Corriente</option>
						</select>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Numero de cuenta</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].numero_cuenta_1"
						/>
					</td>
				</tr>
			</table>
		</div>
		<div class="flex w-full mt-4 justify-center">
			<table class="table-fixed mx-10">
				<tr>
					<th colspan="2" class="border-2 px-4 py-2 bg-gray-200">Pago Movil</th>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Banco</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].banco_movil"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Nombre</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="localId[0].nombre"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">C.I. - RIF</th>
					<td class="border-2 px-4 py-2 w-full flex">
						<select
							:id="select"
							@change="cedulaPrefijoMovil($event)"
							class="ml-1 bg-white"
						>
							<option disabled selected value="">{{
								prefijoCedula(datosIdBancarios[0].cedula_movil)
							}}</option>
							<option value="V">V</option>
							<option value="J">J</option>
							<option value="G">G</option>
							<option value="E">E</option>
						</select>

						<input
							class="w-full ml-2 focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].cedula_movil"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Pago Movil</th>
					<td class="border-2 px-4 py-2 w-64">
						<div class="flex content-center">
							<select
								id="select"
								@change="telefonoBancaMovilPrefijo($event)"
								class="ml-1 bg-white"
							>
								<option disabled selected value="">{{
									prefijoTelefono(datosIdBancarios[0].numero_telefono)
								}}</option>
								<option value="0412">0412</option>
								<option value="0414">0414</option>
								<option value="0424">0424</option>
								<option value="0416">0416</option>
								<option value="0426">0426</option>
							</select>
							<input
								class="ml-1"
								type="text"
								v-model="datosIdBancarios[0].numero_telefono"
							/>
						</div>
					</td>
				</tr>
			</table>

			<table class="table-fixed mx-10">
				<tr>
					<th colspan="2" class="border-2 px-4 py-2 bg-gray-200">
						Datos Bancarios 2
					</th>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Banco</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].banco_2"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Nombre</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].nombre_2"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">C.I. - RIF</th>
					<td class="border-2 px-4 py-2 w-64 flex">
						<select
							:id="select"
							@change="cedulaPrefijoBanco2($event)"
							class="ml-1 bg-white"
						>
							<option disabled selected value="">{{
								prefijoCedulaBanco2(datosIdBancarios[0].cedula_2)
							}}</option>
							<option value="V">V</option>
							<option value="J">J</option>
							<option value="G">G</option>
							<option value="E">E</option>
						</select>
						<input
							class="w-full ml-4 focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].cedula_2"
						/>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Tipo de cuenta</th>
					<td class="border-2 px-4 py-2 w-64">
						<select
							name="estado"
							v-model="datosIdBancarios[0].tipo_cuenta_2"
							class="px-1 ml-2 focus:outline-none bg-white"
						>
							<option value="0">Ahorro</option>
							<option value="1">Corriente</option>
						</select>
					</td>
				</tr>
				<tr>
					<th class="border-2 px-4 py-2">Numero de cuenta</th>
					<td class="border-2 px-4 py-2 w-64">
						<input
							class="w-full focus:outline-none"
							type="text"
							v-model="datosIdBancarios[0].numero_cuenta_2"
						/>
					</td>
				</tr>
			</table>
		</div>
		<div class="w-full px-40">
			<div class="flex justify-star ml-20">
				<input
					class="h-4 w-4 mt-1 mr-1"
					type="checkbox"
					v-model="localId[0].contribuyente_especial"
				/>
				<span class="underline"> Contribuyente especial</span>
			</div>
		</div>
		<div
			v-if="mostrarMensaje"
			class="flex w-full content-center items-center justify-center pb-10"
		>
			<p>{{ message }}</p>
		</div>
		<div class="w-full my-10 items-center justify-center">
			<div class="flex w-full content-center items-center justify-center">
				<Botonp
					class="underline"
					botonpText="Guardar proveedor"
					@click="guardarProveedor"
				></Botonp>
			</div>
		</div>
	</div>
</template>

<script>
import Botonp from "@/components/botonPrimario";
import gql from "graphql-tag";
import vSelect from "vue-select";
import "@/assets/styles/vue-select.css";
import userVue from "../components/user.vue";
import { intToBoolean } from "../functions.js";

export default {
	components: {
		Botonp,
		vSelect
	},
	data() {
		return {
			select: "",
			selectRubro: [],
			opciones: [],
			nuevoObjeto: {},
			nuevoObjetoBanca: {},
			userIdRubros: 0,
			unaVez: false,
			localId: [
				{
					contribuyente_especial: false,
					nombre: "",
					descuento_general: "",
					ubicacion: "",
					show: "",
					user: {
						name: "",
						email: "",
						user_id: "",
						numero_telefono_local: "",
						numero_telefono_movil: "",
						cedula_identidad: ""
					}
				}
			],
			datosIdBancarios: [
				{
					numero_cuenta_2: "",
					tipo_cuenta_2: "",
					cedula_2: "",
					nombre_2: "",
					banco_2: "",
					numero_telefono: "",
					cedula_movil: "",
					banco_movil: "",
					numero_cuenta_1: "",
					tipo_cuenta_1: "",
					cedula_1: "",
					nombre_1: "",
					banco_1: ""
				}
			],
			cargaRubros: false,
			auxObject: {},
			otraVez: false,
			mostrarMensaje: false,
			message: "",
			prefijoObjecto: {
				datosPersonalesCI: "",
				pagoMovilCI: "",
				datoBancario1CI: "",
				datoBancario2CI: "",
				pagoMovilTelefono: "",
				telefonoPersonal: ""
			}
		};
	},
	apollo: {
		rubrosTotal: {
			query: gql`
				query rubrosTotal($filtro: String!) {
					rubrosTotal(filtro: $filtro) {
						rubro
					}
				}
			`,
			variables: {
				filtro: "rubro"
			},
			fetchPolicy: "cache-and-network",
			update: data => data.rubrosTotal,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	methods: {
		intToBoolean,
		telefonoMovilPrefijo(event) {
			this.prefijoObjecto.telefonoPersonal = event.target.value;
		},
		telefonoBancaMovilPrefijo(event) {
			this.prefijoObjecto.pagoMovilTelefono = event.target.value;
		},
		cedulaPrefijoBanco1(event) {
			this.prefijoObjecto.datoBancario1CI = event.target.value;
		},
		cedulaPrefijoMovil(event) {
			this.prefijoObjecto.pagoMovilCI = event.target.value;
		},
		cedulaPrefijoDatosPersonales(event) {
			this.prefijoObjecto.datosPersonalesCI = event.target.value;
		},
		cedulaPrefijoBanco2(event) {
			this.prefijoObjecto.datoBancario2CI = event.target.value;
		},
		telefonoPrefijo(event) {
			console.log(event.target.value);
		},
		prefijoTelefono(numero) {
			return numero[0] + numero[1] + numero[2] + numero[3];
		},
		prefijoCedulaBanco2(numero) {
			return numero[0];
		},
		prefijoCedula(numero) {
			return numero[0];
		},
		datosGenerales() {
			if (
				this.rubrosTotal.length > 0 &&
				!this.cargaRubros &&
				this.localId.length > 0 &&
				this.datosIdBancarios.length > 0
			) {
				this.cargaRubros = true;

				this.nuevoObjeto = JSON.parse(JSON.stringify(this.localId[0]));
				this.nuevoObjetoBanca = JSON.parse(
					JSON.stringify(this.datosIdBancarios[0])
				);
				this.prefijoObjecto.datosPersonalesCI = this.nuevoObjeto.user.cedula_identidad[0];

				this.nuevoObjeto.user.cedula_identidad = this.nuevoObjeto.user.cedula_identidad.substring(
					1
				);

				this.prefijoObjecto.telefonoPersonal =
					this.nuevoObjeto.user.numero_telefono_movil[0] +
					this.nuevoObjeto.user.numero_telefono_movil[1] +
					this.nuevoObjeto.user.numero_telefono_movil[2] +
					this.nuevoObjeto.user.numero_telefono_movil[3];

				this.nuevoObjeto.user.numero_telefono_movil = this.nuevoObjeto.user.numero_telefono_movil.substring(
					4
				);
				this.prefijoObjecto.pagoMovilCI = this.nuevoObjetoBanca.cedula_movil[0];

				this.nuevoObjetoBanca.cedula_movil = this.nuevoObjetoBanca.cedula_movil.substring(
					1
				);

				this.prefijoObjecto.datoBancario1CI = this.nuevoObjetoBanca.cedula_1[0];
				this.nuevoObjetoBanca.cedula_1 = this.nuevoObjetoBanca.cedula_1.substring(
					1
				);

				this.prefijoObjecto.datoBancario2CI = this.nuevoObjetoBanca.cedula_2[0];

				this.nuevoObjetoBanca.cedula_2 = this.nuevoObjetoBanca.cedula_2.substring(
					1
				);

				this.prefijoObjecto.pagoMovilTelefono =
					this.nuevoObjetoBanca.numero_telefono[0] +
					this.nuevoObjetoBanca.numero_telefono[1] +
					this.nuevoObjetoBanca.numero_telefono[2] +
					this.nuevoObjetoBanca.numero_telefono[3];

				this.nuevoObjetoBanca.numero_telefono = this.nuevoObjetoBanca.numero_telefono.substring(
					4
				);

				for (let i = 0; i < this.rubrosTotal.length; i++) {
					this.opciones.push(this.rubrosTotal[i].rubro);
				}
				for (let i = 0; i < this.localId[0].tipo_local.length; i++) {
					this.selectRubro.push(this.localId[0].tipo_local[i].rubro);
				}
			}
		},

		guardarProveedor() {
			this.message = "Datos Guardados Exitosamente";
			this.mostrarMensaje = true;
			var llenarRubro = [];
			if (this.selectRubro.length > 0 && !this.unaVez) {
				this.unaVez = true;

				for (let i = 0; i < this.selectRubro.length; i++) {
					llenarRubro.push({ rubro: this.selectRubro[i] });
				}
			}
			this.$store.state.isLoading = true;
			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation($data: UsersUpdate!) {
							crearUsuario(input: $data) {
								id
							}
						}
					`,
					// Parameters
					variables: {
						data: {
							name: this.localId[0].user.name,
							email: this.localId[0].user.email,
							cedula_identidad:
								this.prefijoObjecto.datosPersonalesCI +
								this.localId[0].user.cedula_identidad,
							numero_telefono_movil:
								this.prefijoObjecto.telefonoPersonal +
								this.localId[0].user.numero_telefono_movil,
							numero_telefono_local: this.localId[0].user.numero_telefono_local,
							password:
								"password",
							address: "Caracas",
							roles: {
								connect: [{ role_id: 3 }]
							},
							locales: {
								create: [
									{
										nombre: this.localId[0].nombre,
										descuento_general: parseFloat(
											this.localId[0].descuento_general
										),
										contribuyente_especial: this.localId[0]
											.contribuyente_especial,
										show: this.intToBoolean(this.localId[0].show),
										ubicacion: this.localId[0].ubicacion,
										tipo_local: {
											create: llenarRubro
										}
									}
								]
							},
							datos_bancarios: {
								create: [
									{
										nombre_1: this.datosIdBancarios[0].nombre_1,
										nombre_2: this.datosIdBancarios[0].nombre_2,
										banco_1: this.datosIdBancarios[0].banco_1,
										banco_2: this.datosIdBancarios[0].banco_2,
										cedula_1:
											this.prefijoObjecto.datoBancario1CI +
											this.datosIdBancarios[0].cedula_1,
										cedula_2:
											this.prefijoObjecto.datoBancario2CI +
											this.datosIdBancarios[0].cedula_2,
										banco_movil: this.datosIdBancarios[0].banco_movil,
										numero_telefono:
											this.prefijoObjecto.pagoMovilTelefono +
											this.datosIdBancarios[0].numero_telefono,
										cedula_movil:
											this.prefijoObjecto.pagoMovilCI +
											this.datosIdBancarios[0].cedula_movil,
										numero_cuenta_1: this.datosIdBancarios[0].numero_cuenta_1,
										numero_cuenta_2: this.datosIdBancarios[0].numero_cuenta_2,
										tipo_cuenta_1: this.datosIdBancarios[0].tipo_cuenta_1,
										tipo_cuenta_2: this.datosIdBancarios[0].tipo_cuenta_2
									}
								]
							}
						}
					}
				})
				.then(data => {
					this.$store.state.isLoading = false;
					console.log(data.data.crearUsuario.id);
				})
				.catch(error => {
					// Error
					this.$store.state.isLoading = false;
					console.log("datos local");
					this.message = error;
				});
		}
	}
};
</script>
